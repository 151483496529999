import React from 'react';
import { IDataTableColumn } from '../../../modules/dataTable/DataTable/model';
// import { any } from '../model';

const productColumns = (combined: boolean, uom = 'lbs'): IDataTableColumn<any>[] => {
  const columns = [
    {
      name: 'Machine',
      selector: 'machine_name_with_serial',
      sortable: true,
      omit: combined,
    },
    {
      name: 'Product Name',
      selector: 'product_name',
      sortable: true,
    },
    {
      name: 'Product Type',
      selector: 'product_type',
      sortable: true,
    },
    {
      name: 'Target Product Delivered (lbs)',
      selector: 'target_amt_total_lbs',
      sortable: true,
      right: true,
      sortFunction: (a: Record<string, string>, b: Record<string, string>): unknown => {
        const valA = parseFloat(a.product_delivered_target_lft) || 0;
        const valB = parseFloat(b.product_delivered_target_lft) || 0;
        return valA - valB;
      },
      omit: uom !== 'lbs',
      cell: (row: any) => <p>{`${row.product_delivered_target_lft || 0} lbs`}</p>,
    },
    {
      name: 'Actual Product Delivered (lbs)',
      selector: 'actual_amt_total_lbs',
      sortable: true,
      right: true,
      sortFunction: (a: Record<string, string>, b: Record<string, string>): unknown => {
        const valA = parseFloat(a.product_delivered_actual_lft) || 0;
        const valB = parseFloat(b.product_delivered_actual_lft) || 0;
        return valA - valB;
      },
      omit: uom !== 'lbs',
      cell: (row: any) => <p>{`${row.product_delivered_actual_lft || 0} lbs`}</p>,
    },
    {
      name: 'Target Product Delivered (tons)',
      selector: 'target_amt_total_tons',
      sortable: true,
      right: true,
      sortFunction: (a: Record<string, string>, b: Record<string, string>): unknown => {
        const valA = parseFloat(a.product_delivered_target_lft) || 0;
        const valB = parseFloat(b.product_delivered_target_lft) || 0;
        return valA - valB;
      },
      omit: uom !== 'tons',
      cell: (row: any) => <p>{`${row.product_delivered_target_lft / 2000} tons`}</p>,
    },
    {
      name: 'Actual Product Delivered (tons)',
      selector: 'actual_amt_total_tons',
      sortable: true,
      right: true,
      sortFunction: (a: Record<string, string>, b: Record<string, string>): unknown => {
        const valA = parseFloat(a.product_delivered_actual_lft) || 0;
        const valB = parseFloat(b.product_delivered_actual_lft) || 0;
        return valA - valB;
      },
      omit: uom !== 'tons',
      cell: (row: any) => <p>{`${row.product_delivered_actual_lft / 2000} tons`}</p>,
    },
    {
      name: 'Target Product Delivered (fl oz)',
      selector: 'target_amt_total_fl_oz',
      sortable: true,
      right: true,
      sortFunction: (a: Record<string, string>, b: Record<string, string>): unknown => {
        const valA = parseFloat(a.product_delivered_target_amt) || 0;
        const valB = parseFloat(b.product_delivered_target_amt) || 0;
        return valA - valB;
      },
      omit: uom !== 'fl oz',
      cell: (row: any) => <p>{`${row.product_delivered_target_amt * 128} fl. oz.`}</p>,
    },
    {
      name: 'Actual Product Delivered (fl oz)',
      selector: 'actual_amt_total_fl_oz',
      sortable: true,
      right: true,
      sortFunction: (a: Record<string, string>, b: Record<string, string>): unknown => {
        const valA = parseFloat(a.product_delivered_actual_amt) || 0;
        const valB = parseFloat(b.product_delivered_actual_amt) || 0;
        return valA - valB;
      },
      omit: uom !== 'fl oz',
      cell: (row: any) => <p>{`${row.product_delivered_actual_amt * 128} fl. oz.`}</p>,
    },
    {
      name: 'Target Product Delivered (gallons)',
      selector: 'target_amt_total_gallons',
      sortable: true,
      right: true,
      sortFunction: (a: Record<string, string>, b: Record<string, string>): unknown => {
        const valA = parseFloat(a.product_delivered_target_amt) || 0;
        const valB = parseFloat(b.product_delivered_target_amt) || 0;
        return valA - valB;
      },
      omit: uom !== 'gallons',
      cell: (row: any) => <p>{`${row.product_delivered_target_amt || 0} gallons`}</p>,
    },
    {
      name: 'Actual Product Delivered (gallons)',
      selector: 'actual_amt_total_gallons',
      sortable: true,
      right: true,
      sortFunction: (a: Record<string, string>, b: Record<string, string>): unknown => {
        const valA = parseFloat(a.product_delivered_actual_amt) || 0;
        const valB = parseFloat(b.product_delivered_actual_amt) || 0;
        return valA - valB;
      },
      omit: uom !== 'gallons',
      cell: (row: any) => <p>{`${row.product_delivered_actual_amt || 0} gallons`}</p>,
    },
    {
      name: 'Accuracy',
      selector: 'product_delivered_accuracy',
      sortable: true,
      right: true,
      sortFunction: (a: Record<string, string>, b: Record<string, string>): unknown => {
        const valA = parseFloat(a.product_delivered_accuracy) || 0;
        const valB = parseFloat(b.product_delivered_accuracy) || 0;
        return valA - valB;
      },
    },
  ];

  return columns;
};

export default productColumns;
