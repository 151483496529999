/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Button, Card, CardContent, Typography, Grid } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import { AutoTreatFiltersContext } from '../contexts/AutoTreatFiltersContext';
import { AutoTreatTransactionsContext } from '../contexts/AutoTreatTransactionsContext';
import { columns, nestedColumns } from '../constants/transactionColumns';
import { MachinesContext } from '../../../modules/machinesAdminManagement/contexts/MachinesContext';
import DataTable from '../../../modules/dataTable/DataTable/DataTable';
import filters from '../constants/transactionFilters';
import AutoTreatTransactionFilterBar from '../../../modules/shared/form/AutoTreatTransactionFilterBar';
import TransactionSummaryCharts from '../components/TransactionSummaryCharts';
import SidebarContent from '../components/SidebarContent';
import TransactionRowMenu from '../../realTimeDashboard/components/TransactionRowMenu';
import DetailDrawer from '../../../modules/navigation/components/DetailDrawer';

const AutoTreatTransactionSummary: React.FunctionComponent = () => {
  const {
    errorMessage,
    fetchTransactionCharts,
    fetchTransactions,
    fetchTransaction,
    transactionLoading,
    transaction,
    transactionMetrics,
    transactions,
    transactionsLoading,
    totalTransactionsCount,
    exportTransactions,
    includeProducts,
    setIncludeProducts,
  } = React.useContext(AutoTreatTransactionsContext);

  const { fetchAutoTreatMachines, autoTreatMachines } = React.useContext(MachinesContext);
  const { filterOptions, fetchFilterOptions } = React.useContext(AutoTreatFiltersContext);

  const [page, setPage] = React.useState(1);
  const [filterType] = React.useState('Transactions');
  const [filterValues, setFilterValues] = React.useState<Record<string, string | string[]>>({});
  const [open, setOpen] = React.useState(false);
  const [perPage, setPerPage] = React.useState<number>(100);
  const [filterParamsString, setFilterParamsString] = React.useState<string>('');
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

  const [isDrawerOpen, setDrawerOpen] = React.useState(false);
  const [selectedTransactionGuid, setSelectedTransactionGuid] = React.useState('');

  React.useEffect(() => {
    fetchFilterOptions();
    fetchTransactions();
    fetchAutoTreatMachines();
    fetchTransactionCharts();
  }, []);

  React.useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);

    // Return a function in the callback that will remove the event listener
    // This is a cleanup function that behaves similarly to componentWillUnmount
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  const handleFilter = (filterParams: string) => {
    setPage(1)
    setPerPage(perPage)
    setFilterParamsString(filterParams)
    fetchTransactions(filterParams, '1', perPage.toString());
    fetchFilterOptions(filterParams);
    fetchTransactionCharts(filterParams);
  };

  const handleSelectTransaction = async (transactionGuid: string) => {
    if (!transactionLoading) {
      setDrawerOpen(true);
      fetchTransaction(transactionGuid);
      setSelectedTransactionGuid(transactionGuid);
    }
  };

  const handlePageChange = async (page: number) => {
    setPage(page)
    fetchTransactions(filterParamsString, page.toString(), perPage.toString());
  };

  const handlePerPageChange = async (perPage: number) => {
    setPerPage(perPage)
    setPage(1)
    fetchTransactions(filterParamsString, '1', perPage.toString());
  };

  const ExpandedComponent = (row: any) => {
    return (
      <Grid container justifyContent="center">
        <Grid item xs={10} style={{ border: '1px solid #3f51b5', margin: 15 }}>
          <DataTable
            columns={nestedColumns}
            data={row.data.treatment_products?.length > 0 ? Array.from(new Set(row.data.treatment_products)) : []}
            striped
            highlightOnHover
            pointerOnHover
            noHeader
            noDataComponent={<div style={{ padding: '24px' }}>This transaction has no treatment products.</div>}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            AutoTreat Transaction Summary
          </Typography>
          <br />
        </Grid>
      </Grid>
      <br />
      <Grid justifyContent="space-between" container>
        <Grid item md={12}>
          <AutoTreatTransactionFilterBar
            applyFilter={handleFilter}
            filterChoices={filterOptions}
            filters={filters as any}
            filterType={filterType}
            filterValues={filterValues}
            localStorageMachineKey="machines"
            setFilterValues={setFilterValues}
            showCombineCheckbox={false}
            exportTransactions={exportTransactions}
            includeProducts={includeProducts}
            setIncludeProducts={setIncludeProducts}
            transactionMetrics={transactionMetrics}
            treatmentProductMetrics={null}
          />
          <br />
          <p style={{ color: 'red' }}>{errorMessage}</p>
        </Grid>
      </Grid>
      <DetailDrawer
        open={isDrawerOpen}
        recordLoading={transactionLoading}
        onClose={() => setDrawerOpen(false)}
        bodyComponent={<SidebarContent transaction={transaction} />}
        menuComponent={<TransactionRowMenu transaction={transaction} />}
      />
      <br />
      {windowWidth > 1000 ? (
        <TransactionSummaryCharts />
      ) : (
        <>
          <Button
            variant="contained"
            color="primary"
            style={{ color: '#fff', marginBottom: 10 }}
            disableElevation
            onClick={() => {
              setOpen(!open);
            }}
          >
            {open ? 'Hide Charts' : 'Show Charts'}
          </Button>
          <Collapse in={open}>
            <TransactionSummaryCharts />
          </Collapse>
        </>
      )}
      <br />
      <Card>
        <CardContent>
          <DataTable
            columns={columns}
            data={transactions}
            defaultSortAsc={false}
            defaultSortField="order_start_date_and_time_utc"
            expandableRows
            expandableRowsComponent={<ExpandedComponent row />}
            highlightOnHover
            noHeader
            pagination
            paginationServer
            paginationTotalRows={totalTransactionsCount}
            paginationDefaultPage={page}
            onChangePage={(page) => handlePageChange(page)}
            onChangeRowsPerPage={(per_page) => handlePerPageChange(per_page)}
            paginationPerPage={perPage}
            paginationRowsPerPageOptions={[25, 50, 100, 500]}
            pointerOnHover
            progressPending={transactionsLoading}
            striped
            onRowClicked={(row) => {
              handleSelectTransaction(row.ksi_transaction_guid);
            }}
          />
        </CardContent>
      </Card>
    </>
  );
};

export default AutoTreatTransactionSummary;
