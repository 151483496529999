import React from 'react';
import axios from '../../../modules/utils/axios.utils';
import { AutoTreatTransaction } from '../model';

interface AutoTreatTransactionsContextInterface {
  emailTransaction?: (transactionId?: string, email?: string, utcOffset?: any, onSuccess?: () => void) => Promise<void>;
  errorMessage?: string;
  exportEmail?: string;
  exportTransactions?: (
    exportEmail: string,
    includeHeader: boolean,
    includeProducts: boolean,
    localTime: any,
    utcOffset: any,
    filterParams: string,
    filterType: string,
    yearlyOverview?: boolean,
    combineMachineTotals?: boolean,
  ) => Promise<void>;
  fetchPaginatedTransactions?: (url?: string) => Promise<void>;
  fetchTransaction?: (transactionGuid?: string) => Promise<void>;
  fetchTransactionCharts?: (filterParams?: string) => Promise<void>;
  fetchTransactions?: (filterParams?: string, page?: string, per_page?: string) => Promise<void>;
  includeProducts?: boolean;
  setIncludeProducts?: any;
  transaction?: AutoTreatTransaction;
  transactionCharts?: Record<string, any>;
  transactionChartsLoading?: boolean;
  transactionLoading?: boolean;
  transactionMetrics?: Record<string, string>;
  transactionPdfSubmitting?: boolean;
  transactions?: AutoTreatTransaction[];
  transactionsLoading?: boolean;
  totalTransactionsCount?: number;
}

const AutoTreatTransactionsContext = React.createContext<AutoTreatTransactionsContextInterface>({});

const AutoTreatTransactionsContextConsumer = AutoTreatTransactionsContext.Consumer;
const AutoTreatTransactionsContextProvider: React.FC = ({ children }) => {
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [exportEmail] = React.useState<string>('');
  const [includeProducts, setIncludeProducts] = React.useState<boolean>(false);
  const [transaction, setTransaction] = React.useState<AutoTreatTransaction>();
  const [transactionCharts, setTransactionCharts] = React.useState<Record<string, any>>({});
  const [transactionChartsLoading, setTransactionChartsLoading] = React.useState<boolean>(false);
  const [transactionLoading, setTransactionLoading] = React.useState<boolean>(false);
  const [transactionMetrics, setTransactionMetrics] = React.useState<Record<string, string>>({});
  const [transactionPdfSubmitting, setTransactionPdfSubmitting] = React.useState(false);
  const [transactions, setTransactions] = React.useState<AutoTreatTransaction[]>([]);
  const [totalTransactionsCount, setTotalTransactionsCount] = React.useState<number>(0);
  const [transactionsLoading, setTransactionsLoading] = React.useState<boolean>(true);

  const fetchTransactionCharts = async (filterParams = '', pageSize = '10') => {
    setTransactionChartsLoading(true);

    const cachedMachines = JSON.parse(localStorage.getItem('machines') || '[]');
    const cachedStartDate = localStorage.getItem('startDate');
    const cachedEndDate = localStorage.getItem('endDate');

    const params = new URLSearchParams({
      page_size: pageSize,
      dateandtimeordercomplete_after: cachedStartDate,
      dateandtimeordercomplete_before: cachedEndDate,
      machine: cachedMachines.join(),
      ...Object.fromEntries(new URLSearchParams(filterParams)),
    }).toString();

    axios
      .get<string, any>(`api/autotreat/analytics/transactions/charts/?${params}`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response: any) => {
        setTransactionCharts({
          orderPerDay: response.data.orders_per_day.map((order: Record<string, string>) => order.value),
          orderPerDayLabels: response.data.orders_per_day.map((order: Record<string, string>) => order.label),
          unitsDeliveredPerDay: response.data.units_delivered_per_day.map(
            (order: Record<string, string>) => order.value,
          ),
          unitsDeliveredPerDayLabels: response.data.units_delivered_per_day.map(
            (order: Record<string, string>) => order.label,
          ),
          unitsDeliveredPerWeek: response.data.units_delivered_per_week.map(
            (order: Record<string, string>) => order.value,
          ),
          unitsDeliveredPerWeekLabels: response.data.units_delivered_per_week.map(
            (order: Record<string, string>) => order.label,
          ),
          ytdUnitsDelivered: response.data.ytd_units_delivered.map((order: Record<string, string>) => order.value),
          yoyUnitsDelivered: response.data.yoy_units_delivered.map((order: Record<string, string>) => order.value),
        });
        setTransactionChartsLoading(false);
      })
      .catch((error) => {
        setErrorMessage(error?.response?.data?.Message);
        setTransactionChartsLoading(false);
        setTransactionCharts({});
        setTransactionsLoading(false);
      });
  };

  const fetchTransactions = async (filterParams = '', page = '1', per_page = '100') => {
    setTransactionsLoading(true);
    const cachedMachines = JSON.parse(localStorage.getItem('machines') || '[]');
    const cachedStartDate = localStorage.getItem('startDate');
    const cachedEndDate = localStorage.getItem('endDate');

    const params = new URLSearchParams({
      page: page,
      per_page: per_page,
      dateandtimeordercomplete_after: cachedStartDate,
      dateandtimeordercomplete_before: cachedEndDate,
      machine: cachedMachines.join(),
      ...Object.fromEntries(new URLSearchParams(filterParams)),
    }).toString();

    axios
      .get<string, any>(`api/autotreat/analytics/transactions/?${params}`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        setTransactions(response.data.results);
        setTotalTransactionsCount(response.data.pagination.total_count);
        setTransactionsLoading(false);
        // TODO ANDY need to make sure we are returning these fields and that they match
        setTransactionMetrics({
          total_records: response.data.total_record_count,
          total_units_delivered: response.data.total_units_delivered,
          treatment_accuracy: response.data.treatment_accuracy,
          next_page: response.data.next,
          previous_page: response.data.previous,
        });
      })
      .catch((error) => {
        setErrorMessage(error?.response?.data?.Message);
        setTransactions([]);
        setTransactionsLoading(false);
      });
  };

  const emailTransaction = async (transactionId, email, utcOffset, onSuccess?: () => void) => {
    setTransactionPdfSubmitting(true);

    axios
      .post<any, any>(
        `api/autotreat/analytics/transactions/pdf`,
        { email, transactionId, utcOffset },
        { headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` } },
      )
      .then((response) => {
        setTransactionPdfSubmitting(false);

        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((error) => {
        setTransactionPdfSubmitting(false);
      });
  };

  const exportTransactions = async (
    exportEmail,
    includeHeader,
    includeProducts,
    localTime,
    utcOffset,
    filterParams,
    filterType,
    yearlyOverview,
    combineMachineTotals,
  ) => {
    setTransactionsLoading(true);

    const cachedMachines = JSON.parse(localStorage.getItem('machines') || '[]');
    const cachedStartDate = localStorage.getItem('startDate');
    const cachedEndDate = localStorage.getItem('endDate');

    axios
      .post<any, any>(
        `api/autotreat/analytics/exports/transactions`,
        {
          cachedEndDate,
          cachedMachines,
          cachedStartDate,
          exportEmail,
          includeHeader,
          includeProducts,
          localTime,
          utcOffset,
          filterParams,
          filterType,
          combineMachineTotals,
          yearlyOverview,
        },
        { headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` } },
      )
      .then((response) => {
        setTransactionsLoading(false);
      })
      .catch((error) => {
        setTransactionsLoading(false);
      });
  };

  const fetchPaginatedTransactions = async (url = '') => {
    setTransactionsLoading(true);

    axios
      .get<string, any>(`${url}`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        setTransactions(response.data.results);
        setTransactionsLoading(false);
        setTransactionMetrics({
          total_records: response.data.count,
          total_units_delivered: response.data.total_units_delivered,
          treatment_accuracy: response.data.treatment_accuracy,
          next_page: response.data.next,
          previous_page: response.data.previous,
        });
      })
      .catch((error) => {
        setErrorMessage(error?.response?.data?.Message);
        setTransactions([]);
        setTransactionsLoading(false);
      });
  };

  const fetchTransaction = async (transactionGuid = '') => {
    setTransactionLoading(true);

    axios
      .get<string, any>(`api/autotreat/analytics/transactions/${transactionGuid}/`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        setTransaction(response.data.results[0]);
        setTransactionLoading(false);
      })
      .catch((error) => {
        setErrorMessage(error?.response?.data?.Message);
        setTransaction(null);
        setTransactionLoading(false);
      });
  };

  return (
    <AutoTreatTransactionsContext.Provider
      value={{
        emailTransaction,
        errorMessage,
        exportEmail,
        exportTransactions,
        fetchPaginatedTransactions,
        fetchTransaction,
        fetchTransactionCharts,
        fetchTransactions,
        includeProducts,
        setIncludeProducts,
        transaction,
        transactionCharts,
        transactionChartsLoading,
        transactionLoading,
        transactionMetrics,
        transactions,
        transactionsLoading,
        totalTransactionsCount,
      }}
    >
      {children}
    </AutoTreatTransactionsContext.Provider>
  );
};

export { AutoTreatTransactionsContextProvider, AutoTreatTransactionsContextConsumer, AutoTreatTransactionsContext };
