/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Card, CardContent, Typography, Grid } from '@mui/material';
import DataTable from '../../../modules/dataTable/DataTable/DataTable';
import AutoBulkTransactionFilterBar from '../../../modules/shared/form/AutoBulkTransactionFilterBar';
import filters from '../constants/transactionFilters';
import { transactionColumns, nestedColumns } from '../constants/transactionColumns';
import TransactionSummaryCharts from '../components/TransactionSummaryCharts';
import { AutoBulkFiltersContext } from '../contexts/AutoBulkFiltersContext';
import { AutoBulkTransactionsContext } from '../contexts/AutoBulkTransactionsContext';
import { MachinesContext } from '../../../modules/machinesAdminManagement/contexts/MachinesContext';
import DetailDrawer from '../../../modules/navigation/components/DetailDrawer';
import SidebarContent from '../components/SidebarContent';
import TransactionRowMenu from '../components/TransactionRowMenu';

const AutoBulkTransactionSummary: React.FunctionComponent = () => {
  const {
    transactions,
    transaction,
    transactionMetrics,
    fetchTransactions,
    transactionsLoading,
    totalTransactionsCount,
    fetchTransaction,
    transactionLoading,
    fetchTransactionCharts,
    errorMessage,
    exportTransactions,
    includeProducts,
    setIncludeProducts,
  } = React.useContext(AutoBulkTransactionsContext);

  const { filterOptions, fetchFilterOptions, convertFilterValuesToUrlParams } =
    React.useContext(AutoBulkFiltersContext);
  const { fetchAutoBulkMachines, autoBulkMachines } = React.useContext(MachinesContext);

  const [filterType] = React.useState('Transactions');
  const [filterValues, setFilterValues] = React.useState<Record<string, string | string[]>>({});
  const [page, setPage] = React.useState(1);
  const [perPage, setPerPage] = React.useState<number>(100);
  const [filterParamsString, setFilterParamsString] = React.useState<string>('');
  const [isDrawerOpen, setDrawerOpen] = React.useState(false);

  React.useEffect(() => {
    fetchFilterOptions();
    fetchTransactions();
    fetchTransactionCharts();
    fetchAutoBulkMachines();
  }, []);

  const handleFilter = (filterParams: string) => {
    setPage(1)
    setPerPage(perPage)
    fetchTransactions(filterParams, '1', perPage.toString());
    setFilterParamsString(filterParams)
    fetchTransactionCharts(filterParams);
    fetchFilterOptions(filterParams);
  };

  const handlePageChange = async (page: number) => {
    setPage(page)
    fetchTransactions(filterParamsString, page.toString(), perPage.toString());
  };

  const handlePerPageChange = async (perPage: number) => {
    setPerPage(perPage)
    setPage(1)
    fetchTransactions(filterParamsString, '1', perPage.toString());
  };

  const handleSelectTransaction = async (transactionGuid: string) => {
    if (!transactionLoading) {
      setDrawerOpen(true);
      fetchTransaction(transactionGuid);
    }
  };

  const ExpandedComponent = (row: any) => {
    return (
      <Grid container justifyContent="center">
        <Grid item xs={10} style={{ border: '1px solid #3f51b5', margin: 15 }}>
          <DataTable
            columns={nestedColumns}
            data={row.data.autobulk_transaction_products}
            striped
            highlightOnHover
            pointerOnHover
            noHeader
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            AutoBulk Transaction Summary
          </Typography>
        </Grid>
      </Grid>
      <br />
      <Grid justifyContent="space-between" container>
        <Grid item md={12}>
          <AutoBulkTransactionFilterBar
            applyFilter={handleFilter}
            filterChoices={filterOptions}
            filters={filters as any}
            filterType={filterType}
            filterValues={filterValues}
            localStorageMachineKey="AutoBulkmachines"
            setFilterValues={setFilterValues}
            showCombineCheckbox={false}
            exportTransactions={exportTransactions}
            includeProducts={includeProducts}
            setIncludeProducts={setIncludeProducts}
            transactionMetrics={transactionMetrics}
            treatmentProductMetrics={null}
          />
          <br />
          <p style={{ color: 'red' }}>{errorMessage}</p>
        </Grid>
      </Grid>
      <DetailDrawer
        open={isDrawerOpen}
        recordLoading={transactionLoading}
        onClose={() => setDrawerOpen(false)}
        bodyComponent={<SidebarContent transaction={transaction} />}
        menuComponent={<TransactionRowMenu transaction={transaction} />}
      />
      <br />
      <TransactionSummaryCharts />
      <br />
      <Card>
        <CardContent>
          <DataTable
            columns={transactionColumns}
            data={transactions}
            defaultSortField="work_order_start_date"
            defaultSortAsc={false}
            progressPending={transactionsLoading}
            striped
            highlightOnHover
            pointerOnHover
            noHeader
            expandableRows
            expandableRowsComponent={<ExpandedComponent row />}
            pagination
            paginationServer
            paginationTotalRows={totalTransactionsCount}
            // paginationTotalRows={100}
            paginationDefaultPage={page}
            onChangePage={(page) => handlePageChange(page)}
            onChangeRowsPerPage={(per_page) => handlePerPageChange(per_page)}
            paginationPerPage={perPage}
            paginationRowsPerPageOptions={[25, 50, 100, 500]}
            onRowClicked={(row) => {
              handleSelectTransaction(row.work_order_transaction_guid);
            }}
          />
        </CardContent>
      </Card>
    </>
  );
};

export default AutoBulkTransactionSummary;
